import React from 'react'


import {
    product__available,
    product__unavailable,
} from '../styles/product.module.scss'

const ProductAvailability = ({stockQuantity}) => {
    if (stockQuantity){
        return (
            <div>
                {stockQuantity > 0 ? (
                    <h6 className={product__available}><i class="icofont-info-circle"></i> Produkt dostępny. Ilość sztuk: {stockQuantity}</h6>
                ):(
                    <h6 className={product__unavailable}><i class="icofont-info-circle"></i> Przepraszamy. Produkt aktualnie niedostepny.</h6>
                )}
            </div>
        )
    }

    return null
}


export default ProductAvailability