import React from "react"
import {error__message} from '../styles/checkout.module.scss'

const ErrorMessage = ({ cart }) => {
  const { error } = cart
  if(error){
    return (
        <div className={error__message}>
        <h5
            dangerouslySetInnerHTML={{
            __html: error.message,
            }}
        />
      </div>
    )
    }
  return null
}

export default ErrorMessage
