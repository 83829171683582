import React from 'react'
import { Cart } from '@components/ecommerce'
import { Search } from '@components/theme'
import ModalMenu from './ModalMenu'

import {
    nav,
    nav__list,
    nav__list__item
} from './header.module.scss'

const NavMenu = () => {
	return (
        <nav className={nav}>
            <ul className={nav__list}>
                <li className={nav__list__item}>
                    <Cart />
                </li>
                <li className={nav__list__item}>
                    <Search />
                </li>
                <li className={nav__list__item}>
                    <ModalMenu />
                </li>
            </ul>
        </nav>
)
}

export default NavMenu
