import React from 'react'

const RadioButtons = ({ items=[], itemMapper=(el)=>el }) => {
	return (
		<div className="radio-buttons">
			{items.map((el, key) => {
                const item = itemMapper(el)
				return (
					<div key={key} className={['radio-item', item.className].join(' ')}>
                        <input
                            className='radio-item-input'
                            type='radio'
                            {...item}
						/>
                        <label className='radio-item-label'
                            onClick={item.onChange}
                            >{item.name}</label>
					</div>
				)
			})}
		</div>
	)
}


export default RadioButtons