import React, { useState, useEffect, useContext, useCallback } from "react"
import { navigate, useLocation } from "@reach/router"
import useDelayedRedirect from "./useDelayedRedirect"

const useStateWithMemo = (initialState) => {
  const [state, setState] = useState(initialState)

  const setter = useCallback((toChangeState) => {
    if (state !== toChangeState) {
      setState(toChangeState)
    }
  }, [])

  return [state, setter]
}

const RelocationCtx = React.createContext({
  duringRelocation: false,
  path: "/",
  clickSource: {
    x: 0,
    y: 0,
  },
  onClickSource: () => {},
  onClickLink: () => {},
  onChangeLocation: () => {},
})

const loadClickSource = () => {
  let clickSource = ""
  if (typeof window !== "undefined") {
    clickSource = localStorage.getItem("clickSource")
  }

  if (clickSource) {
    return JSON.parse(clickSource)
  }

  return {
    x: 0,
    y: 0,
  }
}

const DelayedRouter = ({ children }) => {
  const [duringRelocation, onChangeLocation] = useStateWithMemo(false)
  const [path, onClickLink] = useStateWithMemo("/")
  const [clickSource, onClickSource] = useStateWithMemo(loadClickSource())

  useEffect(() => {
    localStorage.setItem("clickSource", JSON.stringify(clickSource))
  }, [clickSource])

  useEffect(() => {
    if (duringRelocation === true) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        onClickLink(path)
        navigate(path)
        onChangeLocation(false)
      }, 1)
    }
  }, [duringRelocation])
  return (
    <RelocationCtx.Provider
      value={{
        duringRelocation,
        onClickLink,
        path,
        onChangeLocation,
        clickSource,
        onClickSource,
      }}
    >
      {children}
    </RelocationCtx.Provider>
  )
}

const DelayedLink = ({ activeClassName, to, children, onClick, className }) => {
  const { onClickLink, onChangeLocation } = useContext(RelocationCtx)
  const { pathname } = useLocation()

  return (
    <a
      href={to}
      className={[className, pathname === to ? activeClassName : ""].join(" ")}
      onClick={(ev) => {
        ev.preventDefault()
        if (pathname !== to) {
          onClick && onClick()
          onChangeLocation(true)
          onClickLink(to)
        }
      }}
    >
      {children}
    </a>
  )
}

export { DelayedRouter, DelayedLink, RelocationCtx, useDelayedRedirect }
