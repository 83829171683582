import React from 'react'
import {product__attributes, 
	product__attribute,
	product__attribute__name,
	product__attribute__line,
	product__attribute__value
} from '../styles/product.module.scss'


const Attributes = ({ attributes }) => {
	if (attributes) {
		return (
			<div className={product__attributes}>
				{attributes.map(({ name, options }, k) => {
					return (
						<div className={product__attribute} key={k}>
							<h6 className={product__attribute__name}>{name}</h6>
							<div className={product__attribute__line} />
							<h6 className={product__attribute__value}>{options.join(' | ')}</h6>
						</div>
					)
				})}
			</div>
		)
	}
	return null
}

export default Attributes
