import React, { Component } from "react"
// import {QuantityChanger} from '@components/shared'
import Variants from "./Variants"
import {
  product__price,
  product__btns,
  product__btn,
  product__btnAlt,
  product__prices,
} from "../styles/product.module.scss"

const cleanPrice = (price) => {
  const _p = price.replace(" ", "")
  return parseFloat(_p.split("&nbsp;")[0])
}

const Prices = ({ sale, regularValue, value }) => {
  return (
    <div className={product__price}>
      <small>{value}PLN</small>

      {sale && <span>{regularValue}PLN</span>}
    </div>
  )
}

class AddToCartWithVariants extends Component {
  state = {
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variant_id: null,
    variant: null,
    sale: false,
    price: 0,
    regularPrice: 0,
    unit: "PLN",
    name: "",
    slug: "",
  }

  changeQty = (val) => {
    const { price, regularPrice } = this.state
    const _number = Number(val)
    if (_number <= 0 || Number.isNaN(_number)) {
      return false
    } else {
      this.setState({
        quantity: _number,
        value: _number * price,
        regularValue: _number * regularPrice,
      })
    }
  }

  changeVariant = ({ variationId, regularPrice, salePrice }) => {
    const { quantity } = this.state
    if (salePrice) {
      this.setState({
        variant_id: variationId,
        price: cleanPrice(salePrice),
        value: cleanPrice(salePrice) * quantity,
        regularPrice: cleanPrice(regularPrice),
        regularValue: cleanPrice(regularPrice) * quantity,
      })
    } else {
      this.setState({
        variant_id: variationId,
        price: cleanPrice(salePrice),
        value: cleanPrice(salePrice) * quantity,
        regularPrice: cleanPrice(regularPrice),
        regularValue: cleanPrice(regularPrice) * quantity,
      })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.variant_id === null) {
      let updatedState = { ...state }
      const { variableProduct } = props
      const [first] = variableProduct.variations.nodes
      if (variableProduct.salePrice) {
        updatedState = {
          ...updatedState,
          product_id: variableProduct.productId,
          variant_id: first.variationId,
          name: variableProduct.name,
          slug: variableProduct.slug,
          sale: true,
          price: cleanPrice(first.salePrice),
          value: cleanPrice(first.salePrice),
          regularPrice: cleanPrice(first.regularPrice),
          regularValue: cleanPrice(first.regularPrice),
        }
      } else {
        updatedState = {
          ...updatedState,
          product_id: variableProduct.productId,
          variant_id: first.variationId,
          name: variableProduct.name,
          slug: variableProduct.slug,
          price: cleanPrice(first.regularPrice),
          regularPrice: cleanPrice(first.regularPrice),
          regularValue: cleanPrice(first.regularPrice),
          value: cleanPrice(first.regularPrice),
        }
      }
      return updatedState
    }
    return { ...state }
  }

  render() {
    const { quantity, value, regularValue, sale, variant_id } = this.state
    const { variableProduct } = this.props
    return (
      <div>
        <div className={product__prices}>
          <div>
            {variableProduct.variations && (
              <div className="justify-content-center">
                <Variants
                  variations={variableProduct.variations}
                  selected={this.state.variant_id}
                  onVariantChange={this.changeVariant}
                />
              </div>
            )}
          </div>
          <div className="justify-content-center">
            {/*
						<QuantityChanger onChange={this.changeQty} quantity={quantity}/>
					*/}
            <Prices {...{ value, regularValue, sale }} />
          </div>
        </div>

        <div className={product__btns}>
          <a
            className={product__btn}
            onClick={() => {
              this.props.onAddToCart(this.state)
            }}
          >
            <i className="icofont-plus"></i>
            <i class="fas fa-shopping-cart"></i>
          </a>
          <a className={`${product__btn} ${product__btnAlt}`} href="/checkout/">
            <i className="icofont-credit-card"></i>
            <i className="icofont-double-right"></i>
          </a>
        </div>
      </div>
    )
  }
}

export default AddToCartWithVariants
