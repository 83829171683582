import React, { Component } from 'react'
import { ProductItem } from '@components/ecommerce'
import { Slider } from '@components/shared'


const localStorageKey = 'lastSeenProducts'

class AddProductToLastSeen extends Component {
	render() {
		const { product } = this.props
		if (typeof window !== 'undefined') {
			const products =
				JSON.parse(window.localStorage.getItem(localStorageKey)) || []
			const productsIds = products.map((e) => e.id)

			if (!productsIds.includes(product.id)) {
				products.unshift(product)
				if (products.length > 8) {
					products.length = 8
				}
				window.localStorage.setItem(
					localStorageKey,
					JSON.stringify(products)
				)
			}
		}
		return null
	}
}

class LastSeenProductsSection extends Component {
	render() {
		const { title="Ostatnio oglądane" } = this.props
		if (typeof window !== 'undefined') {
			let products =
				JSON.parse(window.localStorage.getItem(localStorageKey)) || []
			if (products.length > 4) {
				return (
					<section className='products'>
						<div className='container'>
							<div className='row justify-content-center'>
								<h1>{title}</h1>
							</div>
							<div className='products-slider'>
								<Slider
									items={products}
									SliderItem={ProductItem}
								/>
							</div>
						</div>
					</section>
				)
			}
		}

		return null
	}
}

export { AddProductToLastSeen }
export default LastSeenProductsSection
