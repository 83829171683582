import React from 'react'
import { truncate } from 'lodash'

import {AnimatedLink} from '@components/shared'
import {
	breadcrumbs,
	breadcrumbs__link,
	breadcrumbs__link__active,
	breadcrumbs__spacing,
	breadcrumbs__span,
} from './breadcrumbs.module.scss'

const Span = ({ html, className }) => {
	return (
		<span
			dangerouslySetInnerHTML={{ __html: html }}
			className={className}
		/>
	)
}

const Breadcrumbs = ({ elements = [] }) => {
	const first = { label: 'Główna', url: '/' }
	const [second, third, fourth] = elements
	return (
		<div className={breadcrumbs}>
			<AnimatedLink
				to={first.url}
				className={breadcrumbs__link}
				activeClassName={breadcrumbs__link__active}
			>
				{first.label}
			</AnimatedLink>
			{second && (
				<React.Fragment>
					<span className={breadcrumbs__spacing}>{' / '}</span>
					<AnimatedLink
						className={breadcrumbs__link}
						to={second.url}
						activeClassName={breadcrumbs__link__active}
					>
						<Span html={truncate(second.label, { length: 60 })} />
					</AnimatedLink>
				</React.Fragment>
			)}
			{third && (
				<React.Fragment>
					<span className={breadcrumbs__spacing}>{' / '}</span>
					<AnimatedLink
						className={breadcrumbs__link}
						to={third.url}
						activeClassName={breadcrumbs__link__active}

					>
						<Span html={truncate(third.label, { length: 60 })} />
					</AnimatedLink>
				</React.Fragment>
			)}
			{fourth && (
				<React.Fragment>
					<span className={breadcrumbs__spacing}>{' / '}</span>
					<AnimatedLink
						className={breadcrumbs__link}
						activeClassName={breadcrumbs__link__active}
						to={fourth.url}
					>
						<Span
							html={truncate(fourth.label, { length: 60 })}
							className={breadcrumbs__span}
						/>
					</AnimatedLink>
				</React.Fragment>
			)}
		</div>
	)
}

export default Breadcrumbs
