import React, { useContext } from "react";
import { WindowCtx } from '../../../reduxWrapper'

import Logo from './Logo'
import NavMenu from './NavMenu'
import NavButtons from './NavButtons'

import {
	header,
	header__scrolling,
	header__container,
} from './header.module.scss'

const Header = () => {
	const {y} = useContext(WindowCtx)
	return (
		<header className={[header, y > 20 ? header__scrolling : ''].join(' ')}>
			<div className={header__container}>
				<Logo />
				<NavButtons/>
				<NavMenu/>
			</div>
		</header>
	);
}

export { Logo }
export default Header
