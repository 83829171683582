import React from "react"
import { Modal } from "@components/shared"
import { AnimatedButton, AnimatedLink } from "@components/shared"
import Logo from "./Logo"

import { modal, modal__menu, modal__menu__item } from "./header.module.scss"

const Toggler = ({ className, onClick }) => {
  return (
    <AnimatedButton className={className} onClick={onClick}>
      <i className="icofont-navigation-menu" />
    </AnimatedButton>
  )
}

const options = [
  { url: "/shop", label: "Materiały do nauki" },
  { url: "/blog", label: "Mądrości programistyczne" },
  { url: "/about", label: "O mnie" },
  { url: "/contact", label: "Współpraca i pytania" },
  { url: "/other-important-links", label: "Inne" },
  // { url: "/tags", label: "Tematy na stronie" },
  // { url: "/regulaminy", label: "Regulaminy" },
]

class ModalMenu extends React.Component {
  modal = React.createRef()

  render() {
    const { className } = this.props

    return (
      <div>
        <Toggler
          className={className}
          onClick={() => {
            this.modal.current.toggle()
          }}
        />
        <Modal title={""} ref={this.modal} className={modal}>
          <ul className={modal__menu}>
            <Logo />
            {options.map((el, k) => {
              return (
                <li key={k} className={modal__menu__item}>
                  <AnimatedLink to={`${el.url}/`}>{el.label}</AnimatedLink>
                </li>
              )
            })}
          </ul>
        </Modal>
      </div>
    )
  }
}

export default ModalMenu
