import React from 'react'
import Slider from 'react-slick'
import { ResizedImage } from '@components/shared'
import { SingleProduct } from '@components/ecommerce'


const GallerySettings = {
	dots: false,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	speed: 400,
	autoplaySpeed: 5000,
	cssEase: 'ease-in-out',
}
const CrossSettings = {
	...GallerySettings,
	slidesToShow: 3,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			},
		},
	],
}

const GallerySlide = ({ image, onClick }) => {
	return (
		<div className='gallery-slide'>
			<a onClick={onClick}>
				<ResizedImage {...image} size={'300w'} />
			</a>
		</div>
	)
}

const GallerySlider = ({ galleryImages, onClick }) => {
	return (
		<Slider {...GallerySettings}>
			{galleryImages.nodes.map((el, k) => (
				<GallerySlide onClick={()=>{onClick(el)}} image={el} key={k} />
			))}
		</Slider>
	)
}

const CrossSellingSlider = ({crossSell}) => {
	return (
		<Slider {...CrossSettings}>
			{crossSell.nodes.map((el, k) => {
				return (
					<div key={k} className="products-slide">
						<SingleProduct
							slug={el.slug}
							image={el.image}
							price={el.price}
							name={el.name}
						/>
					</div>
				)
			})}
		</Slider>
	)
}

export { GallerySlider, CrossSellingSlider }
