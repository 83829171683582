import React from "react"
import { ProductSummaryItem } from "@components/ecommerce"

import { cart__items } from "../cart.module.scss"

const Products = ({ items, changeProductQty, removeFromCart }) => {
  return (
    <div className={cart__items}>
      {items.map((el, k) => (
        <ProductSummaryItem
          key={k}
          {...el}
          changeProductQty={changeProductQty}
          removeFromCart={removeFromCart}
        />
      ))}
    </div>
  )
}

export default Products
