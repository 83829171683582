import React from 'react'
import { TextInput } from '@components/shared'

import './auth.scss'

const LoginForm = ({ store, changeValue, onLogin }) => {
	return (
		<div className="auth-form">
			<form
				className="customer-form"
				onSubmit={(ev) => {
					ev.preventDefault()
				}}
			>
				<h5>Zaloguj się</h5>
				<div className='row justify-content-center'>
					<div className="col-12 col-md-6">
						<TextInput
							placeholder='email'
							name='email'
							value={store.customer.email}
							validate={'text'}
							onChange={(ev) => {
								changeValue({
									email: ev.target.value,
								})
							}}
						/>
						<TextInput
							placeholder='Haslo'
							name='password'
							value={store.customer.password}
							validate={'text'}
							type={'password'}
							onChange={(ev) => {
								changeValue({
									password: ev.target.value
								})
							}}
						/>
					</div>
				</div>
				<div className='row justify-content-center'>
					<button className='app-button' onClick={onLogin}>ZALOGUJ</button>

				</div>
			</form>
		</div>
	)
}

export default LoginForm