import React from 'react'
import ReactDOM from 'react-dom'
import { AnimatedButton, AnimatedSection } from '@components/shared'

import {
	modal,
	modal__close,
	modal__bg,
	modal__content,
	modal__header,
	modal__header__close
} from './modal.module.scss'

class Modal extends React.Component {
	state = { imOpen: false }

	toggle = (cond) => {
		this.setState({
			imOpen: cond || !this.state.imOpen,
		})
	}
	render() {
		const {
			title = 'Modal Header',
			close = true,
			lockBackground = false,
			bgColor = 'auto',
			noAnimation = false,
			onClose = () => false,
			className,
		} = this.props
		const { imOpen } = this.state
		if (!imOpen) {
			return null
		}
		return ReactDOM.createPortal(
			<React.Fragment>
				<a
					className={modal__bg}
					style={{
						backgroundColor: bgColor,
					}}
					onClick={() => {
						if (!lockBackground) {
							this.toggle(false)
							onClose()
						}
					}}
				/>
				<div className={[modal, className].join(' ')}>
					<div className={[modal__header, title === '' ? modal__header__close : ''].join(' ') }>
						{title && <h3>{title}</h3>}
						{close && (
							<AnimatedButton
								className={modal__close}
								onClick={() => {
									this.toggle(false)
									onClose()
								}}
							>
								<i className='icofont-close' />
							</AnimatedButton>
						)}
					</div>
					<AnimatedSection>
						<div className={`${modal__content}`}>
							{this.props.children}
						</div>
					</AnimatedSection>
				</div>
			</React.Fragment>,
			document.querySelector('#portal')
		)
	}
}

export default Modal
