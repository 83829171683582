import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AnimatedLink, ResizedImage as Image  } from '@components/shared'

import {
	logo
} from './header.module.scss'

const Logo = () => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "gladiators_logo") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)
	return <Image {...WP.mediaItemBy} size={'300w'} />
}

export default () => (
    <div className={logo}>
        <AnimatedLink to={'/'}>
            <Logo />
        </AnimatedLink>
    </div>
)
