import memoize from "fast-memoize"
import { getSortedSrcSet } from "@components/shared"

const multiplier = 0.5

const onScroll = ({ windowHeight }, ref) => {
  if (ref.current) {
    const { offsetTop, offsetHeight } = ref.current

    const { top, bottom } = ref.current.getBoundingClientRect()

    const canSeeTop = top - windowHeight < 0
    const canSeeBottom = bottom > 0

    if (canSeeTop && canSeeBottom) {
      const processingSee =
        (-1 * (top - windowHeight)) / (windowHeight + offsetHeight)

      const calc = (-0.5 + processingSee) * 100 * multiplier

      return -50 + calc
    }

    return -50
  } else {
    return -50
  }
}

const defaultImg =
  "https://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/05/parallax_img.jpg"

const getProperImgSize = memoize((image, { windowWidth }) => {
  let fittedImg
  const sortedSrcSet = getSortedSrcSet(image.srcSet)

  for (const idx in sortedSrcSet) {
    const [properImgSize, _, sizes] = sortedSrcSet[idx]
    if (sizes && sizes[0] >= windowWidth) {
      fittedImg = properImgSize
      break
    }
  }
  return fittedImg || defaultImg
})

export { onScroll, getProperImgSize }
