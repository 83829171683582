import  { useState,  useCallback } from 'react'

const useMemoState = (initialState)=>{
	const [state, setState] = useState(initialState)

	const setter = useCallback((toChangeState)=>{
		if(state !== toChangeState){
			setState(toChangeState)
		}
	},[])

	return [state, setter]
}

export default useMemoState